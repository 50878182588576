// rewrite react-toaster styles
.success-toaster {
  background-color: var(--mui-palette-success-main);
  color: white;
  font-weight: 600;
  font-size: 12px;
}
.error-toaster {
  background-color: var(--mui-palette-error-main);
  color: white;
  font-weight: 600;
  font-size: 12px;
}
.warning-toaster {
  background-color: var(--mui-palette-warning-main);
  color: white;
  font-weight: 600;
  font-size: 12px;
}
.Toastify__toast {
  border-radius: 32px 0 0 32px;
  min-height: auto;
  max-width: 500px;
}
.Toastify__close-button {
  color: white;
  opacity: 0.9;
}
